<template>
  <div>
    <v-dialog v-model="notify.needBuySession" width="400px">
      <v-card class="modal buyModal">
        <div>
          <div class="imageBox text-center">
            <img src="../../assets/Notify/notifyPackage.svg" width="104px" alt="">
          </div>
          <div class="text-center mt-5">
            <div class="titleModal">
              بسته‌ای وجود ندارد
            </div>
            <div class="descModal">
              برای رزرو جلسه جدید بسته تهیه کنید
            </div>
          </div>
          <div class="ActionArea mt-15 text-center">
            <v-btn color="#46b1a1" class="actionArea elevation-0" :to="{name:'packages',query:{'step':'packages'}}">
              افزایش اعتبار
            </v-btn>
            <br>
            <v-btn text color="#46b1a1" class="mt-3" @click="notify.needBuySession = false">
              بعدا افزایش می‌دهم
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="notify.needReservedSession" width="400px">
      <v-card class="modal reserveModal">
        <div>
          <div class="imageBox text-center">
            <img src="../../assets/Notify/reserveSession.svg" width="" alt="">
          </div>
          <div class="text-center mt-5">
            <div class="titleModal">
              رزرو جلسه جدید
            </div>
            <div class="descModal">
              مایل هستید جلسه جدید برای شما تنظیم شود؟
            </div>
          </div>
          <div class="ActionArea mt-15 text-center">
            <v-btn color="#46b1a1" class="actionArea elevation-0" :to="{name:'packages',query:{'step':'reserve'}}">
              رزرو جلسه
            </v-btn>
            <br>
            <v-btn text color="#46b1a1" class="mt-3" @click="notify.needReservedSession = false">
              بعدا رزرو می‌کنم
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    notify: Object,
  },
  data() {
    return {
      test: false
    }
  }
}
</script>

<style scoped>
.modal {
  border-radius: 15px;
  min-height: 458px;
}

.buyModal {
  padding-top: 28%;
}

.reserveModal {
  padding-top: 10%;

}

.titleModal {
  font-size: 18px;
  font-weight: bold;
}

.descModal {
  font-size: 15px;
  margin-top: 8px;
}

.actionArea {
  width: 150px !important;
  height: 45px !important;
  margin: 0px auto;
  border-radius: 15px !important;
  color: white;
  font-size: 14px;
}
</style>
