<template>
  <div>
    <div class="sessionStatusCard" v-if="hasDoctor && !profile.state.hasPendingSessions">
      <div class="d-flex flex-column justify-space-around fill-height">
        <div class=" ">
          <div class="d-flex justify-space-between" :class="$vuetify.breakpoint.mdAndDown && 'flex-column'">
            <div class="d-flex " :class="$vuetify.breakpoint.mdAndDown && 'flex-column text-center'">
              <div class="mt-2 mr-2" v-if="doctor">
                <div>
                  <img :src="doctor.avatar" class="doctorImage" width="100px" alt="">
                </div>
                <div class="text-center">
                  <v-btn text x-small class="" color="#3CBE8B" elevation="0" :href="doctor.resume_link"
                         target="_blank">
                    نمایش رزومه
                  </v-btn>
                </div>
              </div>
              <div class="d-flex flex-column justify-center mr-2">
                <p>مشاور:
                  {{ doctor.full_name }}
                  <br>
                  <v-btn class="mt-2"
                         small
                         outlined
                         color="primary"
                         v-if="doctor.hasOwnProperty('skype_link')"
                         :href="doctor.skype_link"
                         target="_blank"
                  >
                    لینک اسکایپ مشاور
                    <v-icon small class="mx-1">mdi-skype</v-icon>
                  </v-btn>
                </p>
                <div class="statusTitleArea" :class="$vuetify.breakpoint.mdAndDown && ' text-center'">
                  <div class="subTitleDoctor mb-3" :class="!$vuetify.rtl && 'text-left'">
                    سیمیاروم بر اساس جلسه پیش‌مشاوره مناسب ترین مشاور را برای شما انتخاب کرده است.
                  </div>
                </div>
              </div>
            </div>
            <div class=" d-flex justify-center flex-column">
              <v-btn small class="SessionRequestBtn" elevation="0" :to="{name: 'sessions-request'}"
              >
                {{ $t('sessions.session_request') }}
              </v-btn>
              <v-btn x-small text class="mt-2" color="#3CBE8B" elevation="0" href="https://wa.me/989302672054"
                     target="_blank">
                درخواست تغیر‌ مشاور
              </v-btn>
            </div>
          </div>
        </div>
        <div>

        </div>
        <div class="d-flex justify-space-between">
          <div class="mt-3">
          </div>
          <!--          <div class="statusLabel">-->
          <!--            <router-link :to="{name:'onboarding_new'}" style="text-decoration: none">-->
          <!--              <div class="BuyAction d-flex mt-3">-->
          <!--                <div class="ml-2">-->
          <!--                  رزرو جلسه پیش‌مشاوره-->
          <!--                </div>-->
          <!--                <img src="../../assets/image/png/session/buy.png" :class="!$vuetify.rtl && 'en_arrow'"-->
          <!--                     style="border-radius: 5px" alt="">-->
          <!--              </div>-->
          <!--            </router-link>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <div class="sessionStatusCard" v-if=" !hasDoctor && !profile.leadUser && profile.state.attendedPresession">
      <div class="d-flex flex-column justify-space-around fill-height">
        <div class="statusTitle ">
          <div class="d-flex justify-space-between">
            <div class="d-flex">
              <div>
                <img src="../../assets/image/png/session/tik.png" width="40px" class="mt-1" alt="">
              </div>
              <div class="mt-2 mr-2">
                درحال انتخاب بهترین مشاور برای شما هستیم
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="statusTitleArea">
            <div class="needToBuy" :class="!$vuetify.rtl && 'text-left'">
              سیمیاروم بر اساس جلسه پیش‌مشاوره مناسب‌ترین مشاور را برای شما انتخاب می‌کند.
            </div>
          </div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="mt-3">
          </div>
          <!--          <div class="statusLabel">-->
          <!--            <router-link :to="{name:'onboarding_new'}" style="text-decoration: none">-->
          <!--              <div class="BuyAction d-flex mt-3">-->
          <!--                <div class="ml-2">-->
          <!--                  رزرو جلسه پیش‌مشاوره-->
          <!--                </div>-->
          <!--                <img src="../../assets/image/png/session/buy.png" :class="!$vuetify.rtl && 'en_arrow'"-->
          <!--                     style="border-radius: 5px" alt="">-->
          <!--              </div>-->
          <!--            </router-link>-->
          <!--          </div>-->
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
    }),
    hasDoctor() {
      return !Array.isArray(this.doctor)
    },
    doctor() {
      return this.profile.assignedDoctor
    },
  },
  data() {
    return {
      setPreSession: false,
      userOnbording: false,
    }
  },
  methods: {
    checkUser() {
      if ((this.profile.requiredFields.length != 0 || this.profile.cycles.length == 0) && !this.profile.leadUser) {
        this.setPreSession = true
      }
      if (this.profile.requiredFields.length != 0 && this.profile.leadUser) {
        this.userOnbording = true
      }
    }
  },
  created() {
    this.checkUser()
  },
  watch: {
    profile() {
      this.checkUser()
    }
  }
}
</script>

<style scoped>

.doctorImage {
  /*border-radius: 50%;*/
}

.SessionRequestBtn {
  background: #3CBE8B !important;
  margin-top: 20px;
  color: white;
}

.sessionStatusCard {
  width: 100%;
  background: white;
  border-radius: 18px;
  padding: 25px;
  border: solid 1px #d1dcff;
}

.statusTitle {
  font-size: 20px;
}

.statusTitleArea {
  padding-right: 5px;
}

.statusLabel {
  text-decoration: none !important;
}

.needToBuy {
  /* Typo/Title2/Regular/Right */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* identical to box height, or 138% */
  text-align: right;
  /* label secondary */
  color: #959595;
}


.subTitleDoctor {
  /* Typo/Title2/Regular/Right */
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  /* identical to box height, or 138% */
  text-align: right;
  /* label secondary */
  color: #959595;
}


.BuyAction {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3CBE8B;
}

</style>
