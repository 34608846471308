<template>
  <div class="fill-height" style="width: 95%;margin: 0px auto;position:relative" key="2" v-if="profile">
    <LeadUserDashboard v-if="profile.leadUser"/>
    <ClientDashboard v-else/>
    <!--    <PayableFactor v-if="$vuetify.breakpoint.mdAndUp"/>-->


    <!--    <div class="notificationsArea" :class="$vuetify.rtl ? 'rtl_notificationsArea': 'ltr_notificationsArea' ">-->
    <!--      <IndexNotifications :notify="this.notification.notify"/>-->
    <!--      <NotifyModals :notify="this.notification.modal"/>-->
    <!--    </div>-->

  </div>
</template>

<script>
/*eslint-disable*/
// import EventBus from "../../../Lib/EventBus";
import {mapGetters} from 'vuex'
import IndexNotifications from "@/components/Main/IndexNotifications";
import NotifyModals from "@/components/Main/NotifyModals";
import PayableFactor from "@/components/Main/PayableFactor";
import UserSessionStatus from "@/components/Sessions/UserSessionStatus";
import UserWalletStatus from "@/components/User/UserWalletStatus";
import SupportChat from "@/components/Dashboard/SupportChat";
import SessionCalendar from "@/components/Dashboard/SessionCalendar";
import SubmitedInfoNotifyModal from "@/components/Modal/SubmitedInfoNotifyModal";
import UserSetPreSession from "@/components/User/UserSetPreSession";
import UserSelectDoctor from "@/components/User/UserSelectDoctor";
import UserRequiredBuy from "@/components/User/UserRequiredBuy";
import BodyShaming from "@/components/Dashboard/BodyShaming";
import ClientDashboard from "@/components/Dashboard/Layouts/ClientDashboard";
import TestUserDashboard from "@/components/Dashboard/Layouts/TestUserDashboard";
import LeadUserDashboard from "@/components/Dashboard/Layouts/LeadUserDashboard";

export default {
  components: {
    LeadUserDashboard,
    TestUserDashboard,
    ClientDashboard,
    BodyShaming,
    UserRequiredBuy,
    UserSelectDoctor,
    UserSetPreSession,
    SubmitedInfoNotifyModal,
    SessionCalendar,
    SupportChat, UserWalletStatus, UserSessionStatus, PayableFactor, NotifyModals, IndexNotifications
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile'
    }),
    countOnGosesions() {
      if (this.profile) {
        return this.profile.sessions.filter(item => item.can_enter).length
      }
      return 0
    },

  },
  data() {
    return {
      notification: {
        notify: {
          needReservedSession: false,
          needBuySession: false,
        },
        modal: {
          needBuySession: false,
          needReservedSession: false,
        }
      }
    }
  },
  methods: {
    test() {
      this.$router.push('/fa/preorder/sign/68');
    },
    prepareNotification() {

      if (this.profile.state.requireBuy) {
        this.notification.notify.needBuySession = true
      } else if (!this.profile.state.requireBuy && !this.profile.state.hasPendingSessions) {
        this.notification.notify.needReservedSession = true
      }
      // if (this.profile.state.requireBuy && !this.profile.state.hasPendingSessions) {
      //   this.notification.modal.needBuySession = true
      // } else if (this.profile.state.hadSessions > 0 && !this.profile.state.requireBuy && !this.profile.state.hasPendingSessions) {
      //   this.notification.notify.needReservedSession = true
      // }
    }
  },
  mounted() {
    // this.prepareNotification()
    // if (this.profile.state.requireSign) {
    //   this.$emitEvent('notify', {title: this.$t('notify.completeProfile'), color: 'black'})
    //   this.$router.push({name: "userOnboarding"})
    // }
  },
  watch: {
    profile() {
      // this.prepareNotification()
      // if (this.profile().state)
      // if (this.profile.state.requireSign) {
      // this.$emitEvent('notify', {title: this.$t('notify.completeProfile'), color: 'black'})
      // this.$router.push({name: "userOnboarding"})
      // }
      // this.checkRequiredFiled()
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .inviteFriendsContent {
    height: 150px !important;
    width: 50% !important;
    text-align: left !important;
    padding-bottom: 20px !important;
    position: relative !important;
  }

  .inviteFriends {
    position: relative !important;
  }

  .titleInviteFriends {
    font-size: 20px !important;
    text-align: left !important;
  }

  .hintAction {
    text-align: left !important;
  }

  .actionBtnMobile {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .notificationsArea {
    position: relative !important;
    bottom: 0% !important;
    right: 0% !important;
  }
}

.notificationsArea {
  position: fixed;
  bottom: 12%;
}

.rtl_notificationsArea {
  right: 10%;
}

.lrt {
  left: 10%;
}

a {
  text-decoration: none;
}

.testsCard {
  background: url("../../assets/image/testCart.svg") no-repeat;
  background-size: cover;
  height: 110px;
  width: 100%;
  border: 1px solid #6d5aac;
  border-radius: 15px;
  color: #6d5aac;
  font-size: 17px;
}

.inviteFriendsContent {
  width: 30%;

}

.hintAction {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #6d5aac;
}

.actionAreaInviteFriends {
  width: 191px !important;
  height: 40px !important;
  border-radius: 10px !important;
  background-color: #6d5aac;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.titleInviteFriends {
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #6d5aac;
}

.inviteFriends {
  background: url("../../assets/image/inviteBanner.webp") no-repeat;
  background-size: cover;
  border-radius: 15px;
}

.cartAmount {
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.walletTotal {
  border-radius: 10px;
  background-color: #d1dcff;
}

.titleWalletInfo {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000;
}

.walletInfo {
  border-radius: 15px;
  border: solid 1px #d1dcff;
  width: 100%;
  background: white;
}

.headerWalletInfo {
  padding: 30px 30px 0px 30px;
}

.subHeaderWalletInfo {
  padding: 0px 30px 0px 30px;
}

.subTitleWalletInfo {
  font-size: 12px;
  color: black;
}

.walletTotal {
  margin: 18px;
  padding: 50px 20px 5px 20px;
}

.cartTitle {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
}

.cartHint {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
}

.cartCounter {
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  color: white;
  border: 5px solid white;
  border-radius: 50%;
}

.moreInfo {
  font-size: 12px;
  color: white;
}

.statisticsCart {
  border-radius: 15px;
  box-shadow: 0 16px 25px 0 rgba(71, 200, 189, 0.5);
  background-color: #47c8bd;
  padding: 20px 30px;
}

.moreInfoIcon {

  display: inline-block;
}
</style>
