<template>
  <!--  <div class="sessionStatusCard">-->
  <!--    <div class="d-flex flex-column justify-space-around fill-height">-->
  <!--      <div class="statusTitle ">-->
  <!--        <div class="d-flex justify-space-between">-->
  <!--          <div class="d-flex">-->
  <!--            <div>-->
  <!--              <img src="../../assets/image/png/session/icon_status.png" width="40px" class="mt-1" alt="">-->
  <!--            </div>-->
  <!--            <div class="mt-2 mr-2 ">-->
  <!--              {{ $t('dashboard.session_status.title') }}-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          &lt;!&ndash;          <div>&ndash;&gt;-->
  <!--          &lt;!&ndash;            <div class="timeArea">&ndash;&gt;-->
  <!--          &lt;!&ndash;              18:35-may6&ndash;&gt;-->
  <!--          &lt;!&ndash;            </div>&ndash;&gt;-->
  <!--          &lt;!&ndash;          </div>&ndash;&gt;-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div>-->
  <!--        <div class="statusTitleArea">-->
  <!--          &lt;!&ndash;          <div class="waitForCoordination">&ndash;&gt;-->
  <!--          &lt;!&ndash;            کارشناسان ما بزودی با شما تماس خواهند گرفت&ndash;&gt;-->
  <!--          &lt;!&ndash;          </div>&ndash;&gt;-->
  <!--          &lt;!&ndash;          <div class="sessionInfo">&ndash;&gt;-->
  <!--          &lt;!&ndash;            <span style="color: #4597DF">&ndash;&gt;-->
  <!--          &lt;!&ndash;              نام تراپیست :&ndash;&gt;-->
  <!--          &lt;!&ndash;            </span>&ndash;&gt;-->
  <!--          &lt;!&ndash;            <span>&ndash;&gt;-->
  <!--          &lt;!&ndash;              علی امیری&ndash;&gt;-->
  <!--          &lt;!&ndash;            </span>&ndash;&gt;-->
  <!--          &lt;!&ndash;          </div>&ndash;&gt;-->
  <!--          <div class="needToBuy " :class="!$vuetify.rtl && 'text-left'">-->
  <!--            {{ $t('dashboard.session_status.subtitle') }}-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="d-flex justify-space-between">-->
  <!--        <div class="mt-3">-->
  <!--          &lt;!&ndash;          <div class="cycleName">&ndash;&gt;-->
  <!--          &lt;!&ndash;            زوج درمانی&ndash;&gt;-->
  <!--          &lt;!&ndash;          </div>&ndash;&gt;-->
  <!--        </div>-->
  <!--        <div class="statusLabel">-->
  <!--          &lt;!&ndash;          <div class="labelCoordination d-flex">&ndash;&gt;-->
  <!--          &lt;!&ndash;            <div class="ml-2">&ndash;&gt;-->
  <!--          &lt;!&ndash;              در انتظار هماهنگی&ndash;&gt;-->
  <!--          &lt;!&ndash;            </div>&ndash;&gt;-->
  <!--          &lt;!&ndash;            <img src="../../assets/image/png/session/button_status.png" style="border-radius: 5px" alt="">&ndash;&gt;-->
  <!--          &lt;!&ndash;          </div>&ndash;&gt;-->
  <!--          &lt;!&ndash;          <div class="labelSession d-flex mt-3">&ndash;&gt;-->
  <!--          &lt;!&ndash;            <div class="ml-2">&ndash;&gt;-->
  <!--          &lt;!&ndash;              در انتظار برگذاری&ndash;&gt;-->
  <!--          &lt;!&ndash;            </div>&ndash;&gt;-->
  <!--          &lt;!&ndash;            <img src="../../assets/image/png/session/button_status.png" style="border-radius: 5px" alt="">&ndash;&gt;-->
  <!--          &lt;!&ndash;          </div>&ndash;&gt;-->
  <!--          <div class="actionBuy d-flex mt-3">-->
  <!--            <div class="ml-2">-->
  <!--              {{ $t('dashboard.session_status.action') }}-->
  <!--            </div>-->
  <!--            <img src="../../assets/image/png/session/buy.png" :class="!$vuetify.rtl && 'en_arrow'" style="border-radius: 5px" alt="">-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <div v-if="sessions.length != 0">
    <div class="d-flex justify-space-between">
      <div class="titleProfileSection mt-5 ">
        <v-icon small color="#4597DF">mdi-circle</v-icon>
        جلسات پیش‌رو
      </div>
      <div class=" ">

      </div>
    </div>
    <template v-for="(session , i) in sessions">
      <SessionCard @getAllSession="getAllSessionAndRestStore" :key="i" :session="session"/>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SessionCard from "@/components/Sessions/SessionCard";

export default {
  components: {SessionCard},
  computed: {
    ...mapGetters({
      'profile': 'getProfile'
    }),
    sessions() {
      return this.profile.four_future_sessions
    }
  },
  methods: {
    getAllSessionAndRestStore() {
      this.$emitEvent('setLoading', true)
      this.$store.dispatch('setProfileEmpty')
      this.requesting('auth', 'getProfile').finally(() => {
        this.$emitEvent('setLoading', false)
      });
    },
    openSessionRequestModal() {
      this.$router.push({name: "sessions", params: {target: 'request'}})
    }
  }
  // data(){
  //   return{
  //     sessions:null,
  //   }
  // }

}
</script>

<style scoped>





.timeArea {
  background: #D8E9F8;
  height: 30px;
  border-radius: 15px;
  padding: 0px 5px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.sessionStatusCard {
  width: 100%;
  height: 170px;
  background: white;
  border-radius: 18px;
  padding: 25px;
  border: solid 1px #d1dcff;
}

.statusTitle {
  font-size: 20px;
}

.statusTitleArea {
  padding-right: 5px;
}

.statusLabel {

}

.waitForCoordination {
  /* Typo/Title2/Regular/Right */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* identical to box height, or 138% */
  text-align: right;
  /* label secondary */
  color: #959595;
}

.needToBuy {
  /* Typo/Title2/Regular/Right */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* identical to box height, or 138% */
  text-align: right;
  /* label secondary */
  color: #959595;
}


.labelCoordination {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3CBE8B;
}

.cycleName {
  color: #4597DF;
}

.labelSession {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3CBE8B;
}

.actionBuy {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3CBE8B;
}

.sessionInfo {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  /* identical to box height, or 138% */
  text-align: right;

  /* secondary */
  color: black;
}
</style>