<template>
  <div>
    <SubmitedInfoNotifyModal v-if="$route.query.from == 'onboarding'"/>
    <v-row>
      <v-col cols="12" md="8" lg="8">
        <v-row justify="center">
          <v-col cols="12" lg="12">
            <UserSessionStatus class=""/>
          </v-col>
          <v-col cols="12" lg="12">
            <UserGetTell/>
          </v-col>
          <v-col cols="12" lg="12">
            <UserSetPreSession/>
          </v-col>
          <v-col cols="12" lg="12">
            <UserRequiredBuy/>
          </v-col>
          <v-col cols="12" lg="12">
            <UserSelectDoctor/>
          </v-col>

          <!--          <v-col cols="12" lg="6">-->
          <!--            <router-link :to="{name:'packages'}" style="text-decoration: none;color: white">-->
          <!--              <div class="statisticsCart walletSessionCart">-->
          <!--                <div class="d-flex justify-space-between" :class="!$vuetify.rtl && 'flex-row-reverse'">-->
          <!--                  <div class="">-->
          <!--                    <img src="../../assets/icons/wallets.svg" alt="">-->
          <!--                  </div>-->
          <!--                  <div class="moreInfo d-flex" :class="!$vuetify.rtl && 'flex-row-reverse'">-->
          <!--                    <div class="mt-1">-->
          <!--                      {{ $t('dashboard.walletSession.action') }}-->
          <!--                    </div>-->
          <!--                    <div class="moreInfoIcon mt-1 mr-1">-->
          <!--                      <img src="../../assets/icons/more.svg" width="18" alt="">-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="cartIntoArea mt-5 d-flex justify-space-between" v-if="profile.wallet">-->
          <!--                  <div>-->
          <!--                    <div class="cartTitle ">-->
          <!--                      {{ $t('dashboard.walletSession.title') }}-->
          <!--                    </div>-->
          <!--                    <div class="cartHint" :class="!$vuetify.rtl && 'text-left'">-->
          <!--                      {{ $t('dashboard.walletSession.subtitle') }}-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                  <div class="cartCounter en_num">-->
          <!--                    {{ profile.wallet.total }}-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </router-link>-->
          <!--          </v-col>-->
          <!--          <v-col cols="12" lg="6">-->
          <!--            <router-link :to="{name:'sessions'}" style="text-decoration: none;color: white">-->
          <!--              <div class="statisticsCart SessionCart">-->
          <!--                <div class="d-flex justify-space-between" :class="!$vuetify.rtl && 'flex-row-reverse'">-->
          <!--                  <div class="">-->
          <!--                    <img src="../../assets/icons/cartSession.svg" alt="">-->
          <!--                  </div>-->
          <!--                  <div class="moreInfo d-flex" :class="!$vuetify.rtl && 'flex-row-reverse'">-->
          <!--                    <div class="mt-1">-->
          <!--                      {{ $t('dashboard.sessions.action') }}-->
          <!--                    </div>-->
          <!--                    <div class="moreInfoIcon mt-1 mr-1">-->
          <!--                      <img src="../../assets/icons/more.svg" width="18" alt="">-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="cartIntoArea mt-5 d-flex justify-space-between">-->
          <!--                  <div>-->
          <!--                    <div class="cartTitle ">-->
          <!--                      {{ $t('dashboard.sessions.title') }}-->
          <!--                    </div>-->
          <!--                    <div class="cartHint" :class="!$vuetify.rtl && 'text-left'">-->
          <!--                      {{ $t('dashboard.sessions.subtitle') }}-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                  <div class="cartCounter en_num">-->
          <!--                    {{ countOnGosesions }}-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </router-link>-->
          <!--          </v-col>-->
          <!--      <v-col cols="12" lg="4">-->
          <!--        <div class="statisticsCart SessionCart">-->
          <!--          <div class="d-flex justify-space-between">-->
          <!--            <div class="">-->
          <!--              <img src="../../assets/icons/cartSession.svg" alt="">-->
          <!--            </div>-->
          <!--            <div class="moreInfo d-flex">-->
          <!--              <div class="mt-1">-->
          <!--                جزئیات-->
          <!--              </div>-->
          <!--              <div class="moreInfoIcon mt-1 mr-1">-->
          <!--                <img src="../../assets/icons/more.svg" width="18" alt="">-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="cartIntoArea mt-5 d-flex justify-space-between">-->
          <!--            <div>-->
          <!--              <div class="cartTitle">-->
          <!--                جلسات پیش رو-->
          <!--              </div>-->
          <!--              <div class="cartHint">-->
          <!--                جلساتی که رزرو وقت شده-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="cartCounter en_num">-->
          <!--              15-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--      </v-col>-->
        </v-row>
        <v-row>
          <!--          <v-col cols="12" lg="4" v-if="profile.affiliate_wallet">-->
          <!--            <router-link :to="{name : 'wallet',query:{step:'wallet'}}">-->
          <!--              <div class="walletInfo">-->
          <!--                <div class="headerWalletInfo d-flex justify-space-between">-->
          <!--                  <div class="titleWalletInfo">-->
          <!--                    {{ $t('dashboard.wallet.title') }}-->
          <!--                  </div>-->
          <!--                  <div>-->
          <!--                    <img src="../../assets/icons/backWhite.svg" :class="!$vuetify.rtl && 'en_arrow'" alt="">-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="subHeaderWalletInfo d-flex justify-space-between">-->
          <!--                  <div class="subTitleWalletInfo mt-1">-->
          <!--                    {{ $t('dashboard.wallet.subTitle') }}-->
          <!--                  </div>-->
          <!--                  <div>-->
          <!--                    <img src="../../assets/icons/backWhite.svg" :class="!$vuetify.rtl && 'en_arrow'" alt="">-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="walletTotal mt-10">-->
          <!--                  <div class="d-flex justify-space-between">-->
          <!--                    <div>-->
          <!--                      <img src="../../assets/icons/cartIcon.svg" alt="">-->
          <!--                    </div>-->
          <!--                    <div class="cartAmount en_num">-->
          <!--                      {{ withCurrency(profile.affiliate_wallet[profile.currency].balance, profile.currency) }}-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                  <v-divider style="background: #b9a0f6" class="mb-2"></v-divider>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </router-link>-->
          <!--          </v-col>-->
          <!--          <v-col cols="12" lg="8">-->
          <!--            <div class="inviteFriends  d-flex  fill-height" :class="$vuetify.rtl ? 'justify-end' : 'justify-start'">-->
          <!--              <div class="inviteFriendsContent  d-flex flex-column justify-center"-->
          <!--                   :class="$vuetify.breakpoint.mdAndUp ? 'ml-15' : 'ml-5'">-->
          <!--                <div class="titleInviteFriends">-->
          <!--                  {{ $t('dashboard.inviteFriends.title') }}-->
          <!--                </div>-->
          <!--                <div class="text-center " :class="$vuetify.breakpoint.mdAndUp ? 'mt-10' :'mt-5'">-->
          <!--                  <div class="hintAction">-->
          <!--                    {{ $t('dashboard.inviteFriends.subtitle') }}-->
          <!--                  </div>-->
          <!--                  <v-btn class="actionAreaInviteFriends" elevation="0" color="#6d5aac"-->
          <!--                         v-if="$vuetify.breakpoint.mdAndUp">-->
          <!--                    {{ $t('dashboard.inviteFriends.action') }}-->
          <!--                  </v-btn>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="actionBtnMobile" v-if="$vuetify.breakpoint.mdAndDown">-->
          <!--                <img src="../../assets/icons/testMob.svg" width="20px" alt="">-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </v-col>-->
        </v-row>
        <!--        <v-row justify="start">-->
        <!--          <v-col cols="12" lg="4">-->
        <!--            <a href="https://simiaroom.com/tests" style="text-decoration: none">-->
        <!--              <div class="testsCard d-flex px-8 align-center">-->
        <!--                <div class="d-flex  justify-space-between" style="width: 100%">-->
        <!--                  <div>-->
        <!--                    {{ $t('dashboard.tests.title') }}-->
        <!--                  </div>-->
        <!--                  <div style="color: #b9a0f6;">-->
        <!--                    >-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </a>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
      </v-col>
      <v-col cols="12" md="4" lg="4">
        <div class="mt-5">
          <BodyShaming class="my-4"/>

          <SupportChat/>
          <!--          <SessionCalendar class="mt-5"/>-->
        </div>
        <div class="mt-5">
          <UserWalletStatus/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/*eslint-disable*/
import BodyShaming from "@/components/Dashboard/BodyShaming";
import UserRequiredBuy from "@/components/User/UserRequiredBuy";
import UserSelectDoctor from "@/components/User/UserSelectDoctor";
import UserSetPreSession from "@/components/User/UserSetPreSession";
import SubmitedInfoNotifyModal from "@/components/Modal/SubmitedInfoNotifyModal";
import SupportChat from "@/components/Dashboard/SupportChat";
import UserWalletStatus from "@/components/User/UserWalletStatus";
import UserSessionStatus from "@/components/Sessions/UserSessionStatus";
import UserGetTell from "@/components/User/UserGetTell";

export default {
  components: {
    UserGetTell,
    BodyShaming,
    UserRequiredBuy,
    UserSelectDoctor,
    UserSetPreSession,
    SubmitedInfoNotifyModal,
    SupportChat, UserWalletStatus, UserSessionStatus,
  },
}
</script>

<style scoped>

</style>