<template>
  <div>
    <div class="BoxContext">
      <!--      <div class="text-center chartImage">-->
      <!--        <img src="../../assets/photo_2023-10-23_14-21-36.png" alt="">-->
      <!--      </div>-->
      <div class="titleBox">
        تست‌های رایگان روانشناسی
      </div>
      <div class="descBox">
        تست‌های روانشناسی معتبر به همراه تحلیل رایگان
      </div>
      <div class="testsBox mt-2">
        <v-row>
          <v-col cols="6" lg="3">
            <router-link :to="{name:'testStepper',params:{testID:1}}">
              <div class="textBox text-center">
                <img src="../../assets/tests/icons/depression.png" width="50px" alt="">
                <div class="nameTest">
                  تست افسردگی بک
                </div>
              </div>
            </router-link>
          </v-col>
          <v-col cols="6" lg="3">
            <router-link :to="{name:'testStepper',params:{testID:26}}">
              <div class="textBox text-center">
                <img src="../../assets/tests/icons/adhd-150x150.png" width="50px" alt="">
                <div class="nameTest">
                  تست ADHD
                </div>
              </div>
            </router-link>
          </v-col>
          <v-col cols="6" lg="3">
            <router-link :to="{name:'testStepper',params:{testID:4}}">
              <div class="textBox text-center">
                <img src="../../assets/tests/icons/ehmal.png" width="50px" alt="">
                <div class="nameTest">
                  تست اهمال کاری
                </div>
              </div>
            </router-link>
          </v-col>
          <v-col cols="6" lg="3">
            <router-link :to="{name:'testStepper',params:{testID:12}}">
              <div class="textBox text-center">
                <img src="../../assets/tests/icons/2-11.jpg" width="50px" alt="">
                <div class="nameTest">
                  تست اضطراب همیلتون
                </div>
              </div>
            </router-link>
          </v-col>
        </v-row>
      </div>
      <v-divider class="mt-5 mb-5"></v-divider>
      <a href="https://simiaroom.com/tests" target="_blank">
        <div class="actionBox">
          همه تست‌ها
          <v-icon small color="#67748e">mdi-arrow-left</v-icon>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.textBox{
  border: 1px solid rgba(0, 0, 0, .07);
  border-radius: 10px;
  padding: 5px;
  transition: all 0.3s;

}
.textBox:hover{
  border-color: #344767;
  transition: all 0.3s;
}
a {
  color: inherit;
  text-decoration: none;
}

.nameTest {
  font-size: 12px;
  color: #344767;
  font-weight: bold;
}

.BoxContext {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .08);
  border: 1px solid rgba(0, 0, 0, .07);
  padding: 15px;
  border-radius: 10px;
  background: white;
  height: 100%;
}

.titleBox {
  font-size: 18px;
  font-weight: bolder;
  /*margin-top: 25px;*/
  color: #344767;
}

.descBox {
  line-height: 25pt;
  color: #67748e !important;
  font-size: 15px;
  margin-top: 5px;
}

.chartImage img {
  width: 90%;
  height: 182px;
  margin: 0px auto;
  text-align: center;
}

.actionBox {
  color: #67748e !important;
  font-size: 15px;
  padding-top: 5px;
  font-weight: bold;
  text-align: left;

}

</style>