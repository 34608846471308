<template>
  <div>
    <v-row>
      <v-col cols="12" lg="4">
        <a target="_blank" href="https://simiaroom.com/counselor/">
          <div class="BoxContext">
            <img src="../../assets/lead/Frame4.png" width="100%" alt="">
          </div>
        </a>
      </v-col>
      <v-col cols="12" lg="4">
        <a target="_blank" href="https://simiaroom.com/counselor/">
          <div class="BoxContext">
            <img src="../../assets/lead/Frame5.png" width="100%" alt="">
          </div>
        </a>
      </v-col>
      <v-col cols="12" lg="4">
        <a target="_blank" href="https://simiaroom.com/counselor/">
          <div class="BoxContext">
            <img src="../../assets/lead/Frame6.png" width="100%" alt="">
          </div>
        </a>
      </v-col>

    </v-row>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.BoxContext {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .08);
  border: 1px solid rgba(0, 0, 0, .07);
  padding: 15px;
  border-radius: 10px;
  background: white;
  position: relative;
  height: 100%;
}

</style>