<template>
  <div>
    <div class="sessionStatusCard" v-if="profile.state.requireBuy && profile.four_future_sessions.length == 0">
      <div class="d-flex flex-column justify-space-around fill-height">
        <div class="statusTitle ">
          <div class="d-flex justify-space-between">
            <div class="d-flex">
              <div>
                <img src="../../assets/image/png/session/tik.png" width="40px" class="mt-1" alt="">
              </div>
              <div class="mt-2 mr-2">
                <template v-if="profile.leadUser">
                  شما بسته فعالی ندارید، لطفا اقدام به خرید جلسه مشاوره نمایید
                </template>
                <template v-else>
                  برای خرید جلسات بعدی از اینجا اقدام کنید
                </template>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="statusTitleArea">
            <div class="needToBuy" :class="!$vuetify.rtl && 'text-left'">
              <template v-if="profile.leadUser">
                بعد از خرید جلسه یک جلسه پیش‌مشاوره جهت انتخاب مناسب‌ترین مشاور برای شما برگزار خواهد شد.
              </template>
            </div>
          </div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="mt-3">
          </div>
          <div class="statusLabel">
            <router-link :to="{name:'packages'}" style="text-decoration: none">
              <div class="BuyAction d-flex mt-3">
                <div class="ml-2">
                  خرید جلسه
                </div>
                <img src="../../assets/image/png/session/buy.png" :class="!$vuetify.rtl && 'en_arrow'"
                     style="border-radius: 5px" alt="">
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
    })
  },
  data() {
    return {
      setPreSession: false,
      userOnbording: false,
    }
  },
  methods: {
    checkUser() {
      if ((this.profile.requiredFields.length != 0 || this.profile.cycles.length == 0) && !this.profile.leadUser) {
        this.setPreSession = true
      }
      if (this.profile.requiredFields.length != 0 && this.profile.leadUser) {
        this.userOnbording = true
      }
    }
  },
  created() {
    this.checkUser()
  },
  watch: {
    profile() {
      this.checkUser()
    }
  }
}
</script>

<style scoped>


.sessionStatusCard {
  width: 100%;
  background: white;
  border-radius: 18px;
  padding: 25px;
  border: solid 1px #d1dcff;
}

.statusTitle {
  font-size: 20px;
}

.statusTitleArea {
  padding-right: 5px;
}

.statusLabel {
  text-decoration: none !important;
}

.needToBuy {
  /* Typo/Title2/Regular/Right */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* identical to box height, or 138% */
  text-align: right;
  /* label secondary */
  color: #959595;
}


.BuyAction {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3CBE8B;
}

</style>
