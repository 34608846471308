<template>
  <div>
    <!--    profile.cel-->
    <v-dialog persistent width="500px" v-model="editPhoneNumber">
      <v-card>
        <v-card-title class="headerBox white--text font-weight-bold">
          ویرایش شماره تماس
        </v-card-title>
        <v-card-text>
          <v-form ref="phoneForm" class="mt-10">
            <div class="ltrInput">
              <v-text-field outlined style="border-radius: 20px" v-model="phone" :rules="phoneRules"
                            label="شماره تماس واتساپ / تلگرام"></v-text-field>
            </div>
          </v-form>
          <v-btn color="primary" @click="updateTell" elevation="0">
            ثبت شماره تماس
          </v-btn>
          <v-btn text color="red" @click="editPhoneNumber = false">
            لغو
          </v-btn>
        </v-card-text>

      </v-card>
    </v-dialog>
    <div class="sessionStatusCard" v-if="!profile.cel">
      <div class="d-flex flex-column justify-space-around fill-height">
        <div class="statusTitle ">
          <div class="d-flex justify-space-between">
            <div class="d-flex">
              <div>
                <img src="../../assets/image/png/session/tik.png" width="40px" class="mt-1" alt="">
              </div>
              <div class="mt-2 mr-2">
                برای سهولت در ارتباط، شماره تماس خود را وارد کنید
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="statusTitleArea">
            <div class="needToBuy" :class="!$vuetify.rtl && 'text-left'">
              <template v-if="profile.leadUser">
                بعد از خرید جلسه یک جلسه پیش‌مشاوره جهت انتخاب مناسب‌ترین مشاور برای شما برگزار خواهد شد.
              </template>
            </div>
          </div>
        </div>
        <div>
          <div class="statusTitleArea">
            <div class="needToBuy" :class="!$vuetify.rtl && 'text-left'">
              تیم پشتیبانی از طریق واتساپ یا تلگرام با شما ارتباط برقرار می‌کنند.
            </div>
          </div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="mt-3">
          </div>
          <div class="statusLabel">
            <router-link :to="{name:'userOnboarding',params:{lang:'fa'}}" style="color: inherit;text-decoration: none">
              <div class="BuyAction d-flex mt-3"  style="cursor:pointer;">
                <div class="ml-2">
                  وارد کردن شماره تماس
                </div>
                <img src="../../assets/image/png/session/buy.png" :class="!$vuetify.rtl && 'en_arrow'"
                     style="border-radius: 5px" alt="">
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AuthMixins from "@/mixins/AuthMixins";
import EventsMixins from "@/mixins/EventsMixins";

export default {
  mixins: [AuthMixins, EventsMixins],
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
    })
  },
  data() {
    return {
      setPreSession: false,
      userOnbording: false,
      editPhoneNumber: false,
      phone: null
    }
  },
  methods: {
    updateTell() {
      if (this.$refs.phoneForm.validate()) {
        let data = {
          cell: this.phone.replaceAll(' ', ''),
        };
        this.$emitEvent('setLoading', true)
        this.requesting('auth', 'editProfile', '', data).then((resp) => {
          this.WebengageSetUserRequiredFiled(resp.data.profile)
          this.$emitEvent('notify', {
            title: this.$t('userOnboarding.notify.done'),
            color: 'green',
            id: 'notify_success'
          })
          this.editPhoneNumber = false
        }).finally(() => {
          this.$emitEvent('setLoading', false)
        })
      }
    },
    checkUser() {
      if ((this.profile.requiredFields.length != 0 || this.profile.cycles.length == 0) && !this.profile.leadUser) {
        this.setPreSession = true
      }
      if (this.profile.requiredFields.length != 0 && this.profile.leadUser) {
        this.userOnbording = true
      }
    }
  },
  created() {
    this.checkUser()
  },
  watch: {
    profile() {
      this.checkUser()
    }
  }
}
</script>

<style scoped>
.headerBox {
  background-color: #3CBE8B;

}

.sessionStatusCard {
  width: 100%;
  background: white;
  border-radius: 18px;
  padding: 25px;
  border: solid 1px #d1dcff;
}

.statusTitle {
  font-size: 20px;
}

.statusTitleArea {
  padding-right: 5px;
}

.statusLabel {
  text-decoration: none !important;
}

.needToBuy {
  /* Typo/Title2/Regular/Right */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* identical to box height, or 138% */
  text-align: right;
  /* label secondary */
  color: #959595;
}


.BuyAction {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3CBE8B;
}

</style>
