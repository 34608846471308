<template>
  <div>
    <div class="BoxContext fill-height" v-if="showBanner">
      <pre-session-cart/>


    </div>
    <div class="mb-5" v-if="sessions.length != 0">
      <div class="d-flex justify-space-between ">
        <div class="titleProfileSection mt-5 ">
          <v-icon small color="#4597DF">mdi-circle</v-icon>
          جلسات پیش‌رو
        </div>
        <div class=" ">

        </div>
      </div>
      <template v-for="(session , i) in sessions">
        <SessionCard @getAllSession="getAllSessionAndRestStore" :key="i" :session="session"/>
      </template>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import AuthMixins from "@/mixins/AuthMixins";
import {mapGetters} from "vuex";
import SessionCard from "@/components/Sessions/SessionCard";
import PreSessionCart from "@/components/PreSession/preSessionCart.vue";
/*eslint-disable*/
export default {
  components: {PreSessionCart, SessionCard},
  mixins: [AuthMixins],
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
    }),
    user() {
      return JSON.parse(JSON.stringify(this.profile));
    },
    sessions() {
      return this.profile.four_future_sessions
    },
    showBanner() {
      if (this.profile.state.hadSessions == 0 && this.profile.state.hasSessions == 0) {
        return true
      }
      return false
    }
  },
  data() {
    return {
      window: 1,
      phone: null,
    }
  },
  mounted() {
    this.phone = this.user.cel
  },
  methods: {
    getAllSessionAndRestStore() {
      this.$emitEvent('setLoading', true)
      this.$store.dispatch('setProfileEmpty')
      this.requesting('auth', 'getProfile').finally(() => {
        this.$emitEvent('setLoading', false)
      });
    },


  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .titleBox {
    line-height: 28pt;
    min-height: 100px !important;

  }

  .formBox {
    width: 100% !important;
  }

  .BoxContext {
    min-height: 420px !important;
  }

}

.descBox {
  line-height: 25pt;
  color: #67748e !important;
  font-size: 15px;
}

.formBox {
  width: 50%;
  margin: 0px auto;
}

.BoxContext {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .08);
  border: 1px solid rgba(0, 0, 0, .07);
  padding: 15px;
  border-radius: 10px;
  background: white;
  min-height: 300px;
}

.campaignImage {
  border-radius: 10px;
  cursor: pointer;
}

.titleBox {
  font-size: 18px;
  font-weight: bolder;
  margin-top: 8px;
  color: #344767;
}


</style>
