<template>
  <div>
    <div class="BoxContext">
      <v-row>
        <v-col cols="12" lg="8">
          <div class="d-flex fill-height flex-column">
            <div class="subtitle">
              هوش مصنوعی
            </div>
            <div class="titleBox">
              روانشناسی هوشمند
            </div>
            <div class="descBox">
              دغدغه خود را به طور کامل یادداشت کنید تا به کمک هوش مصنوعی، راهنمایی‌هایی از نظر روانشناسی دریافت کنید.
            </div>
            <v-spacer></v-spacer>
            <div class="actionBox">
              <router-link :to="{name:'chatGpt',params:{lang:'fa'}}" style="color: inherit;text-decoration: none">
                <v-divider class="mb-2"></v-divider>
                شروع
                <v-icon small color="#67748e">
                  mdi-arrow-left
                </v-icon>
              </router-link>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="4">
          <div class="imageBox">

          </div>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>
export default {
  name: 'rowBox',
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .descBox {
    text-align: justify;
  }

  .actionBox {
    margin-top: 20px;
  }
}

.actionBox {
  color: #67748e !important;
  font-size: 15px;
  padding-top: 5px;
  text-align: left;
  font-weight: bold;
}

.descBox {
  line-height: 25pt;
  color: #67748e !important;
  font-size: 15px;
}

.BoxContext {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .08);
  border: 1px solid rgba(0, 0, 0, .07);
  padding: 15px;
  border-radius: 10px;
  background: white;
}

.subtitle {
  font-size: 12px;
  color: grey;
}

.titleBox {
  font-size: 18px;
  font-weight: bolder;
  margin-top: 8px;
  color: #344767;
}

.imageBox {
  background: url("../../assets/image/a-sneak-peek-into-2030-with-generative-ai-2200x1500.png");
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  height: 200px;
}
</style>
