<template>
  <div>
    <v-row>
      <!--      <v-col cols="12" lg="4">-->
      <!--        <div class="ChatCard">-->
      <!--          <div class="contentArea">-->
      <!--            <div class="mt-5 mb-6 d-flex justify-start ">-->
      <!--              &lt;!&ndash;          <img src="../../assets/image/support.png" class="mx-2" width="40px" alt="">&ndash;&gt;-->
      <!--              <div class="d-flex justify-center align-center mr-1 ">-->
      <!--                <div class="titleSupport font-weight-bold">-->
      <!--                  تست‌های معتبر روانشناسی به صورت رایگان-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <router-link :to="{name:'tests',params:{lang: 'fa'}}">-->
      <!--            <div class="actionArea">-->
      <!--              <div class="d-flex justify-end">-->
      <!--                <div class="BuyAction d-flex mt-3">-->
      <!--                  <div class="ml-2">-->
      <!--                    تست‌ها-->
      <!--                  </div>-->
      <!--                  <img src="../../../assets/image/supportMessenger.png" :class="!$vuetify.rtl && 'en_arrow'"-->
      <!--                       style="border-radius: 5px" alt="">-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </router-link>-->
      <!--        </div>-->
      <!--      </v-col>-->
      <!--      <v-col cols="12" lg="4">-->
      <!--        <div class="ChatCard">-->
      <!--          <div class="contentArea">-->
      <!--            <div class="mt-5 mb-6 d-flex justify-start ">-->
      <!--              &lt;!&ndash;          <img src="../../assets/image/support.png" class="mx-2" width="40px" alt="">&ndash;&gt;-->
      <!--              <div class="d-flex justify-center align-center mr-1 ">-->
      <!--                <div class="titleSupport font-weight-bold">-->
      <!--                  روانشناسی آنلاین با هوش‌مصنوعی-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <router-link :to="{name:'tests',params:{lang: 'fa'}}">-->
      <!--            <div class="actionArea">-->
      <!--              <div class="d-flex justify-end">-->
      <!--                <div class="BuyAction d-flex mt-3">-->
      <!--                  <div class="ml-2">-->
      <!--                    شروع جلسه-->
      <!--                  </div>-->
      <!--                  <img src="../../../assets/image/supportMessenger.png" :class="!$vuetify.rtl && 'en_arrow'"-->
      <!--                       style="border-radius: 5px" alt="">-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </router-link>-->
      <!--        </div>-->
      <!--      </v-col>-->
      <!--      <v-col cols="12" lg="4">-->
      <!--        <div class="ChatCard">-->
      <!--          <div class="contentArea">-->
      <!--            <div class="mt-5 mb-6 d-flex justify-start ">-->
      <!--              &lt;!&ndash;          <img src="../../assets/image/support.png" class="mx-2" width="40px" alt="">&ndash;&gt;-->
      <!--              <div class="d-flex justify-center align-center mr-1 ">-->
      <!--                <div class="titleSupport font-weight-bold">-->
      <!--                  جلسه پیس‌مشاوره-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <router-link :to="{name:'tests',params:{lang: 'fa'}}">-->
      <!--            <div class="actionArea">-->
      <!--              <div class="d-flex justify-end">-->
      <!--                <div class="BuyAction d-flex mt-3">-->
      <!--                  <div class="ml-2">-->
      <!--                    رزرو جلسه پیش‌مشاوره-->
      <!--                  </div>-->
      <!--                  <img src="../../../assets/image/supportMessenger.png" :class="!$vuetify.rtl && 'en_arrow'"-->
      <!--                       style="border-radius: 5px" alt="">-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </router-link>-->
      <!--        </div>-->
      <!--      </v-col>-->
      <!--      <v-col cols="12" lg="4">-->
      <!--        <div class="ChatCard">-->
      <!--          <div class="contentArea">-->
      <!--            <div class="mt-5 mb-6 d-flex justify-start ">-->
      <!--              &lt;!&ndash;          <img src="../../assets/image/support.png" class="mx-2" width="40px" alt="">&ndash;&gt;-->
      <!--              <div class="d-flex justify-center align-center mr-1 ">-->
      <!--                <div class="titleSupport font-weight-bold">-->
      <!--                  دسترسی به محتوا بلاگ-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <router-link :to="{name:'tests',params:{lang: 'fa'}}">-->
      <!--            <div class="actionArea">-->
      <!--              <div class="d-flex justify-end">-->
      <!--                <div class="BuyAction d-flex mt-3">-->
      <!--                  <div class="ml-2">-->
      <!--                    وبلاگ سیمیاروم-->
      <!--                  </div>-->
      <!--                  <img src="../../../assets/image/supportMessenger.png" :class="!$vuetify.rtl && 'en_arrow'"-->
      <!--                       style="border-radius: 5px" alt="">-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </router-link>-->
      <!--        </div>-->
      <!--      </v-col>-->
      <v-container>
        <div class="pa-lg-5  leadDashboard">
          <v-row>
            <v-col cols="12" lg="12">
              <PreSessionLeadDashboard class="mt-5" v-if="platFormInfoLS?.id != 4"/>
            </v-col>
            <v-col cols="12" lg="7">
              <row-box/>
            </v-col>
            <v-col cols="12" lg="5" class="">
              <BoxWithImg/>
            </v-col>
            <v-col cols="12" lg="6">
              <TestSection class="mt-5"/>
            </v-col>
            <v-col cols="12" lg="6">
              <!--              <roots-in-soil-lead class="mt-5"/>-->
              <DoctorLead class="mt-5"/>
            </v-col>
          </v-row>
        </div>
        <div class="text-center mt-8">
          <div class="font-weight-bold"> ما را در شبکه‌های اجتماعی دنبال کنید</div>
          <div class="d-flex justify-center mt-4">
            <div class="mx-4">
              <a href="https://www.instagram.com/simiaroomcom/">
                <v-icon color="#9f9f9f">mdi-instagram</v-icon>
              </a>
            </div>
            <div class="mx-4">
              <a href="https://www.youtube.com/simiaroom">
                <v-icon color="#9f9f9f">mdi-youtube</v-icon>
              </a>
            </div>
            <div class="mx-4">
              <a href="https://twitter.com/simiaroom">
                <v-icon color="#9f9f9f">mdi-twitter</v-icon>
              </a>
            </div>
          </div>
        </div>
      </v-container>
    </v-row>
  </div>
</template>

<script>
import RowBox from "@/components/Lead/rowBox";
import BoxWithImg from "@/components/Lead/BoxWithImg";
import TestSection from "@/components/Lead/TestSection";
import PreSessionLeadDashboard from "@/components/Lead/PreSessionLeadDashboard";
import RootsInSoilLead from "@/components/Lead/rootsInSoilLead";
import DoctorLead from "@/components/Lead/DoctorLead";
/*eslint-disable*/
export default {
  components: {DoctorLead, RootsInSoilLead, PreSessionLeadDashboard, TestSection, BoxWithImg, RowBox}
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .leadDashboard {
    width: 100% !important;
  }

}

.leadDashboard {
  width: 80%;
  margin: 0px auto;
}

.ChatCard {
  width: 100%;
  cursor: pointer;
  background: white;
  padding: 20px;
  border-radius: 18px;
  border: solid 1px #d1dcff;
  margin-top: 20px;
}

a {
  color: inherit;
  text-decoration: none;
}

.contentArea {
  margin-top: -20px;
}

.titleSupport {
  font-size: 16px;
}

.actionArea {
  margin-top: -20px;
}

.BuyAction {
  color: #4597DF;
}
</style>