<template>
  <div class="selectTimeArea pa-5">
    <v-fade-transition mode="out-in">
      <div :key="12" v-if="times">
        <div v-if="times.length != 0">
          <p>
            زمان‌های خالی
              {{ doctor.full_name }}
          </p>
          <SelectDateTimeSession @nextHandler="nextHandler" :session_id="sessionID" :key="1" :props-times="times"
                                 :user="profile"/>
        </div>
        <div v-else>
          <v-alert
              dense
              text
              type="warning"
              style="border-radius: 10px"
              class="emptyTimeState"
          >
            مشاور شما در حال حاضر زمان خالی ندارد، لطفا تا خالی شدن زمان مشاور صبر کنید و بعدا اقدام به ثبت زمان
            نمایید
          </v-alert>

        </div>
      </div>
      <template v-else>
        <div :key="2" class="text-center">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
      </template>
    </v-fade-transition>
  </div>
</template>
<!--@closeHandler="closeSessionRequestModal"-->
<script>
/*eslint-disable*/
import SelectDateTimeSession from "@/components/OnBoarding/New/SelectDateTimeSession";
import {mapGetters} from "vuex";

export default {
  components: {SelectDateTimeSession},
  props: {
    sessionID: Number,
  },
  data() {
    return {
      times: null,
    }
  },
  computed: {
    ...mapGetters({
      'profile': "getProfile",
    }),
    doctor() {
      return this.profile.assignedDoctor
    },
  },
  methods: {
    getDoctorTime() {
      this.requesting('session', 'getTimesForPreSession', {sessionID: this.sessionID}).then((resp) => {
        this.times = resp.data.doctorTimes
      })
    },
    nextHandler() {
      this.$emit('nextHandler')
    }
  },
  mounted() {
    this.getDoctorTime()
  }
}
</script>

<style scoped>
.selectTimeArea {
  border-radius: 10px;
  /*background: #D8E9F8;*/
}

@media (min-width: 200px) and (max-width: 600px) {
  .emptyTimeState {
    font-size: 12px !important;
  }
}

.emptyTimeState {
  font-size: 15px;
}

</style>