<template>
  <div>
    <div class="buySessionNotify notify" v-if="notify.needBuySession" id="BuySessionNotification">
      <div class="notifyHeader">
        <div>
          <div class="titleNotify">
            {{ $t('notifications.needBuy.title') }}
          </div>
          <div class="descriptionNotify">
            {{ $t('notifications.needBuy.description') }}
          </div>
        </div>
        <div>
          <img class="mt-3" src="../../assets/Notify/info.svg" alt="">
        </div>
      </div>
      <div class="notifyFooter">
        <div>
          <v-btn class="actionBtn" color="#ecf7f5" elevation="0" :to="{name:'packages',query:{'step':'packages'}}">
            {{ $t('notifications.needBuy.action') }}
          </v-btn>
        </div>
      </div>
    </div>
    <div class="buySessionNotify notify" v-if="notify.needReservedSession" id="ReserveSessionNotification">
      <div class="notifyHeader ">
        <div>
          <div class="titleNotify">
            {{ $t('notifications.reserveSession.action') }}
          </div>
          <div class="descriptionNotify">
            {{ $t('notifications.reserveSession.description') }}
          </div>
        </div>
        <div>
          <img class="mt-3" src="../../assets/Notify/info.svg" alt="">
        </div>
      </div>
      <div class="notifyFooter">
        <div>
          <v-btn class="actionBtn" color="#ecf7f5" elevation="0" :to="{name:'packages',query:{'step':'reserve'}}">
            {{ $t('notifications.reserveSession.action') }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notify: Object,
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .notify {
    width: 100% !important;
  }
}

.notify {
  margin-top: 40px;
  width: 396px;
  height: 154px;
  border-radius: 15px;
  border: solid 1px rgb(219, 219, 219);
  background-color: rgb(255, 255, 255);
  padding: 30px 30px;
  transition: all 0.3s;
}

.notify:hover {
  border-color: #4576d8;
  transition: all 0.3s;
}

.notifyHeader {
  display: flex;
  justify-content: space-between;
}

.titleNotify {
  font-size: 15px;
  font-weight: bold;
}

.descriptionNotify {
  font-size: 12px;
  margin-top: 8px;
}

.actionBtn {
  min-width: 120px !important;
  height: 36px !important;
  /*background-color: rgb(70, 177, 161) !important;*/
  color: #46b1a1 !important;
  border-radius: 10px !important;
}

.notifyFooter {
  margin-top: 20px;
  display: flex;
  justify-content: end;
}
</style>