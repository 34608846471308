<template>
  <div>
    <div class="BoxContext">
      <router-link :to="{name:'roots-in-soil'}">
        <img src="../../assets/webText.jpg" width="100%" class="campaignImage" alt="">
      </router-link>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.BoxContext {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .08);
  border: 1px solid rgba(0,0,0,.07);
  padding: 15px;
  border-radius: 10px;
  background: white;
}

.campaignImage{
  border-radius: 10px;
}
</style>