<template>
  <div class="fill-height">
    <div class="BoxContext">
      <div class="boxContent">
        <div class="boxCover"></div>
        <div class="boxContentInner">
          <div class="d-flex flex-column fill-height">
            <div class="titleBox">
              از مقالات وبلاگ ما دیدن کنید
            </div>
            <div class="descBox">
              برترین و به روز ترین مقالات در حوزه ی روانشناسی و مهارت های زندگی از منابع معتبر را از بلاگ سیمیاروم دنبال
              کنید.
            </div>
            <v-spacer></v-spacer>
            <div class="actionBox">
              <a href="https://simiaroom.com/blog" style="text-decoration: none;color: inherit" target="_blank">
                بلاگ سیمیاروم
                <v-icon small color="white">mdi-arrow-left</v-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px ) {
  .BoxContext {
    height: 320px !important;
  }

  .descBox {
    text-align: justify;
  }
}

.actionBox {
  font-size: 15px;
  font-weight: bold;
  text-align: left;

}

.BoxContext {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .08);
  border: 1px solid rgba(0,0,0,.07);
  padding: 15px;
  border-radius: 10px;
  background: white;
  height: 100%;
}

.titleBox {
  font-size: 18px;
  font-weight: bolder;
}

.descBox {
  line-height: 25pt;
  font-size: 15px;
}

.boxContent {
  background: url("../../assets/image/how-blogging-works.png") no-repeat center;
  background-size: cover;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

}

.boxCover {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: linear-gradient(310deg, #3a416f, #141727);
  opacity: 0.8;
}

.boxContentInner {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  color: white;
  padding: 15px;
}

</style>