<template>
  <div>
    <div class="sessionCard mt-5 " :class="session.state == 'used' && 'usedSession'">
      <div class="d-flex flex-column justify-space-around fill-height">
        <!--        <div v-if="session.state == 'doctor_assigned'">-->
        <!--          <SelectTimeSessionCard @nextHandler="nextHandler" :session-i-d="session.id"/>-->
        <!--        </div>-->
        <div class="sessionCardTitle">
          <div class="d-flex justify-end flex-wrap">
            <template v-if="session.reason == 'other'">
              <div class="timeArea d-flex flex-row " dir="ltr" v-if="session.state != 'doctor_assigned'">
                <div class="">
                  <!--                {{ session.fixed_date }}-->
                  {{ session.fixed_date_day }}
                </div>
                <div style="width: 1px;height: 25px;background: black" class="mx-2"></div>
                <div class="en_light">
                  {{ gorgianFilter(session.fixed_date_time_g) }}
                </div>
                <div style="width: 1px;height: 25px;background: black" class="mx-2"></div>
                <div>
                  {{ timeFilter(session.fixed_date_time) }}

                  ({{ profile.tz }})
                </div>
              </div>
            </template>
            <template v-else-if="session.reason == 'extra_session_time'">
              <div class="timeArea d-flex flex-row " dir="ltr" v-if="session.state != 'doctor_assigned'">
                <div class="">
                  <!--                {{ session.fixed_date }}-->
                  {{ session.fixed_date_day }}
                </div>
                <div style="width: 1px;height: 25px;background: black" class="mx-2"></div>
                <div class="en_light">
                  {{ gorgianFilter(session.fixed_date_time_g) }}
                </div>
                <div style="width: 1px;height: 25px;background: black" class="mx-2"></div>
                <div>
                  {{ timeFilter(session.fixed_date_time) }}

                  ({{ profile.tz }})
                </div>
              </div>
              <v-chip color="primary mt-2" x-small>
                بابت دقایق کسری
              </v-chip>
            </template>
            <template v-else>
              <div class="">
                <v-chip color="primary" small>
                  بابت دقایق اضافی
                </v-chip>
              </div>
            </template>
          </div>

        </div>
        <div>
          <div class="sessionCardInfo d-flex justify-space-between"
               :class="$vuetify.breakpoint.mdAndDown && 'flex-column'">
            <div class="mt-4 therapistName">
              <!--            <span style="color:#4597DF;">-->
              <!--              {{ $t('session_card.therapist_name') }}-->
              <!--            </span>-->
              <!--              <span v-if="session.hasOwnProperty('doctor')">-->
              <!--              {{ session.doctor.full_name }}-->
              <!--            </span>-->
              <div class="therapistImage d-flex">
                <div>
                  <img :src="session.doctor.avatar" alt="">
                  <br>
                  <div class="text-center" v-if="!session.is_presession">
                    <v-btn text x-small class="" color="#3CBE8B" elevation="0" :href="session.doctor.resume_link"
                           target="_blank">
                      نمایش رزومه
                    </v-btn>
                  </div>
                </div>
                <div class="d-flex flex-column justify-center mr-2">
                  <div>
                     <span style="color:#4597DF;">
                       <template v-if="!session.is_presession">
                    مشاور:
                       </template>
                  </span>
                    {{ session.doctor.full_name }}
                  </div>
                  <div>
                    <v-btn class="mt-2"
                           small
                           outlined
                           color="primary"
                           v-if="session.doctor.hasOwnProperty('skype_link')"
                           :href="session.doctor.skype_link"
                           target="_blank"
                    >
                      لینک اسکایپ مشاور
                      <v-icon class="mx-1">mdi-skype</v-icon>
                    </v-btn>
                  </div>

                </div>
              </div>

            </div>
            <div class="">
              <div class="statusLabel ">
                <div class="actionArea d-flex mt-3 justify-end">
                  <template v-if="session.state === 'doctor_assigned'">
                    <div class="ml-2">
                      درخواست جلسه
                    </div>
                    <img width="23px" height="23px" :class="!$vuetify.rtl && 'en_arrow'"
                         src="../../assets/image/png/session/button_status.png"
                         style="border-radius: 5px" alt="">
                  </template>
                  <div class="d-flex" v-if="session.state === 'used' ">
                    <div class="ml-2">
                      {{ $t('session_card.used') }}
                      <template v-if="!session.is_presession && session.reason != 'extra_session_time' ">
                        <span @click="downloadFactor" style="cursor: pointer">
                        -
                        دانلود فاکتور
                        </span>

                      </template>

                    </div>
                    <img :class="!$vuetify.rtl && 'en_arrow'" src="../../assets/image/png/session/buy.png"
                         v-if="!session.is_presession "
                         style="border-radius: 5px;transform: rotate(-90deg)" width="23px" height="23px" alt="">
                    <img :class="!$vuetify.rtl && 'en_arrow'" src="../../assets/image/png/session/buy.png"
                         v-else
                         style="border-radius: 5px;" width="23px" height="23px" alt="">
                  </div>
                  <template v-else-if="session.state === 'date_fixed'">
                    <template v-if="session.can_enter">
                      <div class="ml-2">
                        {{ $t('session_card.can_enter') }}
                      </div>
                      <img width="23px" height="23px" :class="!$vuetify.rtl && 'en_arrow'"
                           src="../../assets/image/png/session/button_status.png"
                           style="border-radius: 5px" alt="">
                    </template>
                    <template v-else>
                      <div class="ml-2">
                        {{ $t('session_card.date_fixed') }}
                      </div>
                      <img width="23px" height="23px" :class="!$vuetify.rtl && 'en_arrow'"
                           src="../../assets/image/png/session/button_status.png"
                           style="border-radius: 5px" alt="">
                    </template>
                  </template>
                  <div>
                  </div>
                </div>
                <div class="mt-4 font-weight-bold" style="font-size: 14px"
                     v-if="session.state === 'date_fixed' && !session.can_enter && session.is_presession">
                  لطفا راس ساعت تنظیم شده، در اسکایپ تماس بگیرید.
                </div>
                <template v-if="session.is_presession">
                  <div class="preSessionBadge">
                    پیش مشاوره
                  </div>
                  <!--                  <div class="text-left ">-->
                  <!--                    <v-chip color="primary" small class="mt-5">پیش مشاوره</v-chip>-->
                  <!--                  </div>-->
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import moment from "moment-jalaali";
import {mapGetters} from "vuex";
import SelectTimeSessionCard from "@/components/Sessions/SelectTimeSessionCard";
import SessionMixins from "@/mixins/SessionMixins";
import momentJalali from "moment-jalaali";

export default {
  components: {SelectTimeSessionCard},
  computed: {
    ...mapGetters({
      'profile': "getProfile",
    })
  },
  mixins: [SessionMixins],
  props: {
    session: Object,
  },
  methods: {
    downloadFactor() {
      this.$emitEvent('setLoading', true)
      this.AxiosWithDownload(`user/sessions/${this.session.id}/factor`, `session_${this.session.id}_factor`, 'pdf').then(() => {
        this.$emitEvent('notify', {title: 'دانلود فاکتور جلسه به‌زودی شروع می‌شود', color: 'green'})
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    nextHandler() {
      this.$emitEvent('notify', {
        color: 'green',
        title: 'جلسه شما تنظیم شد.',
      });
      this.$emit('getAllSession')
    },
    gorgianFilter(date) {
      return moment(date).format('dddd') + " " + moment(date).format("YYYY/MM/DD")
    },
    timeFilter(date) {
      return moment(date).format("HH:mm")

    }
  },
  filters: {}
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .titleSession {
    font-size: 14px !important;
  }

  .sessionCard {
    padding: 10px !important;
  }

  .timeArea {
    width: 100%;
    font-size: 11px !important;
    justify-content: space-between !important;
  }


  .therapistName {
    font-size: 12px;
  }

  .actionArea {
    font-size: 12px !important;
  }

  .preSessionBadge {
    width: 90px !important;
    font-size: 12px !important;
    top: 8px !important;
  }
}

.preSessionBadge {
  position: absolute;
  top: 13px;
  right: -20px;
  padding: 3px;
  color: white;
  background: #3CBE8B;
  font-size: 14px;
  width: 115px;
  text-align: center;
  transform: rotate(39deg);

}

.usedSession {
  /*background: #eee !important;*/
  /*background: #ddd !important;*/
  background: linear-gradient(45deg, #ddd 10%, rgba(240, 240, 240, .5)) !important;
  /*box-shadow: inset 0 20px 30px -10px rgba(0,0,0,.1) !important;*/
}

.therapistImage {

}

.therapistImage img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.timeArea {
  /*background: #D8E9F8;*/
  height: auto;
  border-radius: 15px;
  padding: 5px 15px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.timeArea div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.sessionCard {
  width: 100%;
  min-height: 130px;
  background: white;
  border-radius: 18px;
  padding: 25px;
  border: 1px solid #3CBE8B;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.sessionCardTitle {
  font-size: 20px;
}

.sessionCardInfo {
  padding-right: 5px;
}

.statusLabel {

}


.actionArea {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3CBE8B;
}

</style>