<template>
  <div>
    <div class="ChatCard" @click="openChatSupport">
      <div class="contentArea">
        <div class="mt-5 mb-6 d-flex justify-start " >
          <img src="../../assets/image/support.png" class="mx-2" width="40px" alt="">
          <div class="d-flex justify-center align-center mr-1 ">
            <div class="titleSupport">
              {{ $t('sidebar.chat') }}
            </div>
          </div>
        </div>
      </div>
      <div class="actionArea">
        <div class="d-flex justify-end">
          <div class="BuyAction d-flex mt-3">
            <div class="ml-2">
              {{$t('dashboard.chat.action')}}
            </div>
            <img src="../../assets/image/supportMessenger.png" :class="!$vuetify.rtl && 'en_arrow'" style="border-radius: 5px" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openChatSupport() {
      this.$store.dispatch('clearMessages')
      this.$router.push({name: "chat", params: {userId: '1189'}})

      // this.$emitEvent('openChatSupport')
    }
  }
}
</script>

<style scoped>
.ChatCard {
  width: 100%;
  cursor: pointer;
  background: white;
  padding: 20px;
  border-radius: 18px;
  border: solid 1px #d1dcff;
}

a {
  color: inherit;
  text-decoration: none;
}

.contentArea {
  margin-top: -20px;
}

.titleSupport {
  font-size: 16px;
}

.actionArea {
  margin-top: -20px;
}

.BuyAction {
  color: #4597DF;
}
</style>