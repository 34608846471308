<template>
  <div class="calendarArea white  pa-2">
    <div class="titleCalenderBox d-flex justify-space-between px-10">
      <div>
        {{ $t('sidebar.calender.title') }}
      </div>
      <div>
        <v-btn text color="primary" x-small @click="isJalali = !isJalali">
          {{ isJalali ? "میلادی" : "شمسی" }}
        </v-btn>
      </div>
    </div>
    <v-fade-transition mode="out-in">
      <div :key="1" v-if="isJalali">
        <Calendar class="mx-5 pb-5" :flow="{
                                                 step1:'year',
                                                 step2:'month',
                                                 step3:'day',
                                               }"
                  v-model="date"
                  :styles="'font-family: IranSans;padding:0 5px;'"
        >
          <template v-slot:prev-icon>
            <v-btn fab x-small class="elevation-0">
              <v-icon fab small class="grey--text">
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:next-icon>
            <v-btn fab x-small class="elevation-0">
              <v-icon fab class="grey--text">
                mdi-chevron-left
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:small-modal>

          </template>
        </Calendar>
      </div>
      <div class="text-center px-5" :key="2" v-else>
        <div class="gorgian">
          <v-date-picker no-title width="100%" :picker-date.sync="pickerDate" v-model="selectedDate" :events="events"
                         event-color="transparent" @change="selectDay">
            <template v-slot:day-cell="{ date, event }">
              <div :class="['event-day', { 'has-event': event }]">
                {{ date.getDate() }}
              </div>
            </template>
            <template v-slot:default>
            </template>
          </v-date-picker>
        </div>
        <div class="hintArea" style="margin-top: -10px;z-index: 10 ;position: relative">
          <div class="d-flex">
            <div class="TodayNotation"></div>
            <div class="HintLabel">امروز</div>
          </div>
          <div class="d-flex mt-2">
            <div class="HasSessionNotation"></div>
            <div class="HintLabel">روز‌هایی که در آن جلسه‌ دارید</div>
          </div>
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>

/*eslint-disable*/
import Calendar from "@/components/Calendar/Calendar";
import {mapGetters} from "vuex";
import moment from "moment-jalaali";
import SessionMixins from "@/mixins/SessionMixins";

export default {
  mixins: [SessionMixins],
  components: {Calendar},
  computed: {
    ...mapGetters({
      'sessions': 'getSessions',
    }),
    events() {
      if (this.sessions) {
        return this.sessions.map(item => moment(item.fixed_date_time_g).format('YYYY-MM-DD'))
      }
      return []
    }
  },
  data() {
    return {
      pickerDate: null,
      isJalali: true,
      date: null,
      selectedDate: null,
    }
  },
  methods: {
    selectDay(date) {
      this.$emitEvent('FilterModalTrigger', false)
      this.$router.push({name: "sessions", query: {date: moment(date).format('y-M-D')}})
      this.$emitEvent('changeSessionPeriod', moment(date).format('y-M-D'))
    },
    loadCalenderEvents() {
      setTimeout(() => {
        const btns = document.querySelectorAll('button.v-btn');
        btns.forEach((btn) => {
          const eventsDiv = btn.querySelector('div.v-date-picker-table__events');
          if (eventsDiv) {
            btn.classList.add('dayWithEvent');
          }
        });
      }, 500)
    }
  },
  mounted() {
    this.loadCalenderEvents()
  },
  watch: {
    isJalali() {
      this.loadCalenderEvents()
    },
    events() {
      this.loadCalenderEvents()
    },
    pickerDate(newval, oldval) {
      if (newval != oldval) {
        let year = moment(newval).format("YYYY")
        let month = moment(newval).format("MM")
        this.getSessions(year, month, true)
      }
    },
  }
}
</script>

<style>
.v-btn--active{
  display: none;
  color: black !important;
  background: white !important;
  border: none;

}
.dayWithEvent {
  background: #f6fff7 !important;
  border: 2px solid #3CBE8B;
}

.v-application .accent{
  background: none !important;
}
.theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before{
  opacity: 0  ;
}
.v-date-picker-header__value .accent--text button{
  font-family: en-light !important;
}
.v-date-picker-years li{
  font-family: en-light !important;

}
.gorgian .v-btn__content{
  font-family: en-light !important;

}


/*.dayWithEvent .warning {*/
/*  display: none;*/
/*}*/

</style>