<template>
  <div>
    <v-dialog v-model="dialog" persistent width="500px" style="border-radius: 19px">
      <v-card height="500px" style="border-radius: 19px" class="d-flex flex-column justify-center">
        <div class="wellcomeBox ">
          <div class="text-center mb-3">
            <img src="../../assets/image/svg/TIK.svg" alt="">
          </div>
          <div class="titleBox text-center">
            {{ $t('dashboard.modal.title') }}
          </div>
          <div class="contentBox mt-5">
            {{ $t('dashboard.modal.desc') }}
          </div>
          <div class="actionBox text-center mt-12">
            <v-btn class="confirmBtn" @click="confirm()" elevation="0">
              {{ $t('dashboard.modal.action') }}
            </v-btn>
          </div>
        </div>
        <img src="../../assets/image/svg/Right_up.svg" class="RightTop" alt="">
        <img src="../../assets/image/svg/Left_down.svg" class="LeftDown" alt="">
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    }
  },
  methods: {
    confirm() {
      this.dialog = false
      // console.log(this.$route.path)
      this.$router.push(this.$route.path);
    }
  }
}
</script>

<style scoped>
.wellcomeBox {
  padding: 20px;
}

.titleBox {
  color: #4597DF;
  font-size: 20px;
}

.contentBox {
  font-size: 18px;
  text-align: center;
  line-height: 24pt;
  width: 95%;
  margin: 0px auto;
}

.RightTop {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 130px;
}

.LeftDown {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 130px;
}

.wellcomeBox {
  position: relative;
  z-index: 50;
}

.confirmBtn {
  background: #3CBE8B !important;
  border-radius: 9px;
  width: 260px !important;
  height: 50px !important;
  color: white;
  font-size: 16px;
}
</style>